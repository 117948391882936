import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    fontSize: "48px",
    color: "#012653",
  },
  pageSubHeading: {
    fontSize: "24px",
    color: "#012653",
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div
      className="content-body-div"
      style={{ display: "block", padding: "50px 150px" }}
    >
      <div className="inner-page">
        <p className={classes.pageHeading}>Privacy Policy</p>
        <p>
          Cookies are categorized based on the functionality. You can opt-out of
          each cookie category (except strictly necessary cookies) by clicking
          on radio buttonsagainst the category under “Cookies Settings” option
          on cookie banner.
        </p>

        <span className={classes.pageSubHeading}>IP address</span>
        <p>
          Our Website uses Internet Protocol (IP) Addresses. An IP Address is a
          number assigned to your computer by your Internet service provider, so
          you can access the Internet. Generally, an IP address changes each
          time you connect to the Internet (it is a “dynamic” address). We use
          your IP address to report aggregate information on use and to help
          improve the Website.
        </p>
        <p>
          You should be aware that this site is not intended for or designed to
          attract, individuals under the age of 18. We do not collect personally
          identifiable information from any person we actually know is an
          individual under the age of 18.
        </p>
        <p>
          Areas of this Website that collect your Information use industry
          standard secure socket layer encryption (SSL), reasonable security
          controls, appropriate technical, physical organizational security
          controls are employed for protection of data; however, to take
          advantage of this your browser must support encryption protection
          (found in Chrome, Internet Explorer release 3.0 and above).
        </p>

        <span className={classes.pageSubHeading}>Processing purposes</span>
        <p>
          We collect the data to ensure a proper connection buildup, system
          security and to improve our website. The legal basis for such
          processing is Art. 6 para. 1 GDPR. We have a legitimate interest in
          the provision of our website and services, in technical security,
          rectification of malfunctions as well as detection and tracking of
          unauthorized access or attempt of access.
        </p>

        <span className={classes.pageSubHeading}>
          Disclosure to third parties
        </span>
        <p>
          To the extent permitted by law and to fulfil the purpose of this
          website, we may share your Personal Information with agents,
          contractors or partners of the Company in connection with the services
          that these individuals or entities perform for or with the Company.
          These agents, contractors or partners are restricted from using this
          data in any way other than to provide services for the Company or
          services for the collaboration in which they and the Company are
          engaged in (for example, some of our products are developed and
          marketed through joint agreements with other companies). We may, for
          example, provide your information to agents, contractors or partners
          for hosting our databases, for data processing services or so that
          they can mail you information that you requested for. You may remove
          yourself from any such list or notification at any time.
        </p>
        <p>
          The Company reserves the right to share your Personal Information to
          respond to duly authorized information requests of governmental
          authorities or where required by law. In exceptionally rare
          circumstances where national, state or company security is at issue.
          The Company reserves the right to share our entire database of
          visitors and customers with appropriate governmental authorities.
        </p>
        <p>
          We may also be required to disclose your Personal Information in the
          event of a legal investigation or any such other process where we are
          required to do so by{" "}
        </p>
        <p>
          applicable law or where we have to establish our legal rights or where
          disclosure is required to prevent or take action regarding illegal
          activities, suspected fraud or situations involving potential threats
          to the physical safety of any person.
        </p>
        <p>
          To the extent permitted by law, we may also provide your Information
          to a third party in connection with the sale, assignment or other
          transfer of the business of this Website to which the information
          relates, in which case we will require any such buyer to agree to
          treat your Personal Information in accordance with this Privacy
          Policy.
        </p>
        <p>
          As a convenience to our visitors, this Website may contain links to a
          number of sites that we believe may offer useful information. The
          policies and procedures we described here may not apply to those
          sites. We suggest contacting those sites directly for information on
          their privacy, security, data collection and distribution policies.
        </p>

        <span className={classes.pageSubHeading}>Verification of Data</span>
        <p>
          To the extent permitted by law, it will be necessary for us to verify
          the details you have supplied (e.g. your identity, employment history,
          academic qualification and professional credentials). Such
          verification could be done through third party service providers, who
          shall be bound to obligation of confidentiality and data protection.
        </p>

        <span className={classes.pageSubHeading}>Monitoring</span>
        <p>
          Where permitted by applicable law, Techsophy reserves the right to
          monitor, intercept, access and erase all information, material, data
          and communication (including emails, telephonic conversations and
          voicemail recordings) regarding registration and further processing of
          your application through the website or Tool.
        </p>

        <span className={classes.pageSubHeading}>
          How we share your personal data?
        </span>
        <p>
          We will share your personal data with other Tech Mahindra Group or
          third-party companies, to the extent required for the work or work
          opportunity, you are applying for or employment or assignments.
        </p>
        <p>
          In the event that a Techsophy business is sold or integrate with
          another business in total or in part, your details may be disclosed to
          our advisers and prospective purchaser’s advisers and will be passed
          on to the new owners of the business.
        </p>
        <p>
          Please note, for Applicants from European Economic Area (EEA), your
          personal data will be processed in or accessed globally, including but
          not limited from jurisdiction outside the EEA by members of Techsophy
          group and categories of recipients (e.g. third party providers) in
          jurisdiction that do not have equivalent data protection laws to those
          in the EEA for the purpose outlined above. When we transfer your data
          to Techsophy India, we use an intra-company agreement{" "}
        </p>
        <p>
          containing European Commission approved standard contractual clauses.
          When we transfer your data to outside Techsophy group, we use the
          European Commission’s adequacy decisions and rely on standard
          contractual clauses to safeguard the transfer, unless we transfer the
          data to third party, that has implemented binding contractual rules,
          in which case, we may rely on one of those mechanisms to safeguard the
          transfer as applicable and obtain your{" "}
        </p>
        <p>
          consent for the data transfers to the United States and other
          countries. For further information, including, how to obtain a copy of
          the documents used to protect your information, please contact us as
          described in the contact us section below.
        </p>

        <span className={classes.pageSubHeading}>Your Choices and Rights</span>
        <p>
          You have the right to ask Techsophy for copy of your personal data; to
          correct, delete or restrict processing of your persona data; and to
          obtain the personal data you provide in a structured and
          machine-readable format. In addition, you can object to the processing
          of personal data, in some circumstances (in particular, where we do
          not have to process the data to meet a contractual or legal
          requirement). Where we have asked for your consent, you may withdraw{" "}
        </p>
        <p>
          consent at any time. If you have asked to withdraw your consent to
          Techsophy from processing your data, this will not affect any
          processing which has already{" "}
        </p>
        <p>
          aken place at that time. For any such queries you may contact{" "}
          <a href="mailto:legal@techsophy.com" className="mail-link">
            legal@techsophy.com
          </a>
        </p>
        <p>
          These rights may be limited, for example, if fulfilling your request
          would reveal personal data of another person or if you ask us to
          delete information which we are required by law or have compelling
          legitimate interests to keep. If you have unresolved concerns, you
          have the right to complain to the data protection authority.
        </p>

        <span className={classes.pageSubHeading}>
          How long we retain your personal data ?
        </span>
        <p>
          We will retain your personal data only for as long as we need it for
          Techsophy’s legitimate business interest in accordance with applicable
          laws, to fulfil your registration and application process and to
          perform services as you have opted for or to meet legal requirements,
          after which we will take steps to delete your personal data or hold it
          in a form that no longer identifies you.
        </p>

        <span className={classes.pageSubHeading}>
          Updates to this Privacy Notice
        </span>
        <p>
          This Privacy Policy may be updated periodically. We will encourage you
          to check for changes to this Privacy Policy occasionally. On some
          occasions, we may also actively advise you of specific data handling
          activities or significant changes to this Privacy Policy, as required
          by the applicable law.
        </p>

        <span className={classes.pageSubHeading}>Contact us </span>
        <p>
          If you have any question about this Privacy Policy or wish to contact
          us for any reason in relation to our personal data processing, please
          contact us at :{" "}
          <a href="mailto:contact@techsophy.com" className="mail-link">
            contact@techsophy.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
