import React, { useState } from "react";
import * as XLSX from 'xlsx';
import { ReactComponent as UploadSvg } from './../../assets/svg/upload.svg';
import { ReactComponent as SpaceSvg } from './../../assets/svg/space.svg';
import { ReactComponent as RightArrow } from './../../assets/svg/right-arrow.svg';
import { SheetJSFT } from "./xlxs-component/type";
import LoadSpinner from "../../components/common/LoadSpinner";
import { stringify, parse } from 'zipson';
import { request } from '../../services/request';
import CONSTANTS from "../../constants/constants";
import HeadingHeader from "../../components/headerHeading";


const ExcelReader = (props: any) => {

    const [file, setFile] = useState<any>({});
    const [errorFile, setErrorFile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e: any) => {
        const files = e.target.files;
        if (files && files[0]) setFile(files[0]);
    };

    const handleFile = () => {
        setIsLoading(true);
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e: any) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const dataa: any = XLSX.utils.sheet_to_json(ws);
            if (dataa && dataa.length > 0) {
                if (dataa.every((elm: any) => Object.keys(elm).includes('Location')) && dataa.every((elm: any) => Object.keys(elm).includes('Asset Name')) && dataa.every((elm: any) => Object.keys(elm).includes('Qr-code')) && dataa.every((elm: any) => Object.keys(elm).includes('Asset ID'))) {
                    setErrorFile(false);
                    let compressedData = stringify(dataa);
                    postData(compressedData);
                }
                else {
                    setErrorFile(true);
                    setIsLoading(false);
                }
            }
            /* Update state */
        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        };

    }

    const postData = async (compressedData: string) => {
        try {
            const bodyObj = {
                docName: file.name,
                createdBy: sessionStorage.getItem(CONSTANTS.USER_EMAIL),
                data: compressedData
            }
            const resp: any = await request.post(`${process.env.REACT_APP_API_GATEWAY_URL}docsList`, bodyObj);
            if (resp) {
                const decryptedData = parse(resp?.data.data);
                props.sendData(decryptedData);
                props.fielName(file.name)
                setIsLoading(false);
            }
        }
        catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };



    return (
        <div className='leftSide'>
            <LoadSpinner isLoading={isLoading} />
            <div className='lazyLoad'>
                <div className="borderBox">
                    <h1>Innomaint</h1>
                    <p className='subHeading'>Assets QRCode Generator</p>
                    <div><SpaceSvg /></div>
                    <label className='chooseBtn' htmlFor="file"><UploadSvg /> Choose an Excel File</label>
                    {file.name &&
                        <button className={`GenerateAssetsBtn fadeInRight`} title="Generate QRCode" onClick={handleFile}><RightArrow /></button>
                    }
                    <div>
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            className="form-control"
                            id="file"
                            accept={SheetJSFT}
                            onChange={handleChange}
                        />
                    </div>
                    <p className="mb0">{file.name}</p>
                </div>
            </div>
            {errorFile && <p className='errorMsg'>Please Upload a Valid data file</p>}
        </div>
    );
}

export default ExcelReader;