import React, { useState } from "react";
import { Grid } from "@mui/material";
import ExcelReader from "./main/excelReader";
import './../App.css';
import PrintCard from "./main/printCard";


const Home = () => {

  const [printableData, setPrintableData] = useState<any>([]);
  const [showUpload, setShowUpload] = useState(true);
  const [fileName, setFileName] = useState('');

  const onDataRender = (data: any) => {
    setPrintableData(data);
    setShowUpload(false);
  }

  const handleExcelEle = (val: boolean) => {
    setShowUpload(val);
  }

  const getFileName = (val:string) => {
    setFileName(val);
  }

  return (
    <>
      <Grid style={{ padding: "0px 30px" }}>
        {showUpload ?
          <ExcelReader sendData={onDataRender} fielName={getFileName}/> 
          :
          <PrintCard data={printableData} handleExcel={handleExcelEle} file={fileName}/>
        }
      </Grid>
    </>
  );
};

export default Home;
