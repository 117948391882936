import React from "react";
import QRCode from "react-qr-code";
import { ReactComponent as PrintSvg } from "./../../assets/svg/print-theme.svg";
import { printStyling } from "./xlxs-component/printStyle";
import { SheetJSFT } from "./xlxs-component/type";
import { ReactComponent as UploadSvg } from './../../assets/svg/upload.svg';

const PrintCard = (props: any) => {
    const handlePrint = () => {
        // var printContents = document.getElementById('printablediv1').innerHTML;
        // var originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;


        var mywindow: any = window.open('', 'PRINT');
        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        mywindow.document.write('<link rel="stylesheet" href="src/App.css">');
        mywindow.document.write('</head><body>');
        mywindow.document.write('<style>' + printStyling + '</style>');
        mywindow.document.write(document?.getElementById('printablediv')?.innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        setTimeout(() => {
            mywindow.print();
            mywindow.close()
        }, 1000)
    }

    const showMainUpload = () => {
        props.handleExcel(true);   
    }


    return (
        <div className="rightSide">

            <div className="text-right">
                {/* {props.data.length > 0 && */}
                    <>
                    {props.hideChooseFile !== true && 
                        <label className='chooseBtn' onClick={showMainUpload}><UploadSvg /> Choose an Excel File</label>}
                        <button className="printBtn fadeInRight" onClick={handlePrint}><PrintSvg /> Print</button>               
                    </>
                {/* } */}
            </div>
            {props.data.length ?
                <>
                    <p>{props.file} <span style={{color:'#F36F21'}}>(Total {props.data.length}: QR Codes...!)</span></p>
                    <div id="printablediv" className="fadeInUp">
                        {props.data.map((d, i) => (
                            <div className="grid" key={i}>
                                <p className="assetsName">{d['Asset Name'].substring(0, 45)}</p>
                                <div className="qrColm">
                                    <QRCode className="qrCode" size={54} value={d['Qr-code']} />
                                    <div>
                                        <p className="assetsId">{d['Asset ID']}</p>
                                    </div>
                                </div>
                                <p className="assetsLocation">{d['Location']}</p>
                            </div>
                        ))}
                    </div>
                </>
                :
                ''
            }
        </div>
    );

}

export default PrintCard;