import React from "react";

const HeadingHeader = () => {
    return (
        <div className="mainHead">
            <h1>Innomaint</h1>
            <p className='subHeading' style={{color: '#000'}}>Assets QRCode Generator</p>
        </div>
    );
}

export default HeadingHeader; 