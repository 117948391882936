import React, { useEffect, useState } from "react";
import { request } from '../services/request';
import { parse } from 'zipson';
import { Grid } from "@mui/material";
import LoadSpinner from "../components/common/LoadSpinner";
import PrintCard from "./main/printCard";
import {ReactComponent as CloseIcon} from './../assets/svg/material-symbols_close.svg';

const tableHead = ['S.no', 'File Name', 'Created By', 'Created On', 'View']
type dataObj = {
    docName: string;
    createdBy: string;
    createdOn: string
}
const DocsList = (props: any) => {
    const [docs, setDocs] = useState<dataObj[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [printableData, setPrintableData] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        fetchAll();
    }, []);

    const fetchAll = async () => {
        setIsLoading(true);
        try {
            const resp: any = await request.get(`${process.env.REACT_APP_API_GATEWAY_URL}docsList`);
            setDocs(resp.data);
            setIsLoading(false);
        }
        catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };

    const sendDecryptData =(index: number, obj: any) => {
        setIsLoading(true);
        console.time()
        parsePromise(obj)
        .then(data => {
            setPrintableData(data);
            setShowModal(true);
            setIsLoading(false);
            setFileName(obj.docName)
        })
        .catch(err => {
            console.log(err)
        })
        // props.sendData(decryptedData);
        
    }

    const parsePromise = (obj: any) => {
        return new Promise((resolve, reject) => {
            try {
                resolve(parse(obj.data))
            } catch (err) {
                reject(err)
            }
        })
    }

    const getDateFormat = (date: string) => {
        const newDate = new Date(date);
        const hours = ("0" + newDate.getHours()).slice(-2);
        const min = ("0" + newDate.getMinutes()).slice(-2);
        const dateNum = ("0" + newDate.getDate()).slice(-2);
        const month = newDate.toLocaleString('default', { month: 'short' });
        const year = newDate.getFullYear();
        return dateNum + '-' + month + '-' + year + ' :: ' + hours + ':' + min;
    }

    return (
        <div style={{ padding: "0px 30px" }}>
            <LoadSpinner isLoading={isLoading} />
            {showModal &&
                <>
                    <div className="overlay" onClick={()=>setShowModal(false)}></div>
                    <div className="modalPopup">
                        <CloseIcon className="modalClose" onClick={()=>setShowModal(false)} style={{float:'right'}} />
                        <PrintCard data={printableData} file={fileName} hideChooseFile={true}/>
                    </div>
                </>
            }
            <table className="table">
                <thead>
                    <tr>
                        <th className="text-left">S.no</th>
                        <th className="text-left">File Name</th>
                        <th className="text-left">Created By</th>
                        <th className="text-center">Created On</th>
                        <th className="text-center">View</th>
                    </tr>
                </thead>
                <tbody>
                    {docs.length ?
                        docs.map((d, i) => (
                            <tr key={i}>
                                <td className="text-left">{i + 1}</td>
                                <td className="text-left">{d.docName}</td>
                                <td className="text-left">{d.createdBy}</td>
                                <td className="text-center">{getDateFormat(d.createdOn)}</td>
                                <td className="text-center"><button className="viewData" onClick={() => sendDecryptData(i, d)}>View</button></td>
                            </tr>
                        ))
                        :
                        <tr> <td colSpan={5}>~No Data~</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default DocsList;